import {
  type AppVersionData,
  type VPApiDataResponse,
} from '../types/interfaces';
import { config } from '../config/configStore';
import { VP_API_END_POINTS } from '../config/vpApiEndpoints';

export const getAppDownloadUrl =
  async (): Promise<VPApiDataResponse<AppVersionData> | null> => {
    try {
      const response = await fetch(
        `${config.vpApi.url}/${VP_API_END_POINTS.dowloadApp}`,
        {
          method: 'GET',
        }
      );
      if (response.status === 200) {
        return (await response.json()) as VPApiDataResponse<AppVersionData>;
      }
      return null;
    } catch (error) {
      console.error('Unable to fetch file url', error);
      return null;
    }
  };
