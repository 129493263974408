import { Column } from '../../components/gridElements';
import { COLORS } from '../../types/styles';
import { GetStartedPanel } from './getStartedPanel';
import { InfoList } from './infoList';

export const Home: React.FC = () => {
  return (
    <>
      <Column
        flex="40%"
        background={`linear-gradient(5.09deg, ${COLORS.TERTIARY} -4.15%,${COLORS.SECONDARY} 32.84%,${COLORS.PRIMARY} 85.79%)`}
        color={COLORS.SECONDARY_TEXT}
      >
        <InfoList />
      </Column>
      <Column flex="60%" padding="14vh 9vw 10vh 9vw">
        <GetStartedPanel />
      </Column>
    </>
  );
};
