import styled from '@emotion/styled';
import { Button } from '../../components/button';
import { Spacer } from '../../components/spacer';
import copy from '../../assets/copy.svg';
import { getAppDownloadUrl } from '../../services/downloadUrlService';
import { downloadFile } from '../../utils/downloadFile';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const StyledDiv = styled.div`
  display: flex;
  justify-content: start;

  button {
    margin-right: 20px;
  }

  button:last-child {
    margin-right: 0px;
  }
`;

const SpanStyled = styled.span({
  display: 'block',
  margin: '1vh 0',
  fontSize: '0.8em',
});

const CopyDiv = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: '48px',
  background: 'rgba(255, 255, 255, 0.25)',
  border: '2px solid #DBDBD9',
  borderRadius: '4px',
  borderStyle: 'solid none solid solid',
});

const CopyButton = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '48px',
  height: '48px',
  background: '#FFFFFF',
  border: '2px solid #DBDBD9',
  borderRadius: '0px 4px 4px 0px',
  ':hover': {
    background: '#DBDBD9',
  },
});

const StudyLink = styled.input({
  padding: '12px 0px 12px 18px',
  width: '100%',
  clear: 'both',
  display: 'inline-block',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  backgroundColor: 'transparent',
  border: 'none',
  outline: 'none',
  fontFamily: 'TTNorms',
  fontWeight: '500',
  fontSize: '16px',
  margin: 0,
});

export const GetStartedPanel: React.FC = () => {
  const location = useLocation();
  const [studyLink, setStudyLink] = useState<string>('');

  useEffect(() => {
    let queryStringParams = location.search;
    if (queryStringParams.includes('&amp;')) {
      queryStringParams = queryStringParams.replace(/&amp;/g, '&');
    }
    setStudyLink(
      `${process.env.VP_DESKTOP_SITE as string}/${queryStringParams}`
    );
  }, [location]);

  const clickHandler = async (): Promise<void> => {
    try {
      const result = await getAppDownloadUrl();
      if (!result?.data?.fileUrl) {
        console.error('Unable to retrieve data');
        return;
      }
      downloadFile(result.data.fileUrl);
    } catch (error) {
      console.error('Unable to dowload file', error);
    }
  };

  function onCopyClipboard(): void {
    navigator.clipboard.writeText(studyLink).then(
      function () {
        alert('Copying to clipboard was successful!');
      },
      function (_err) {
        alert('Could not copy study link');
      }
    );
  }

  return (
    <>
      <h2>Step 1: Download Vision Project app</h2>
      <p>
        To participate in the study, download Vision Project app by clicking on
        appropriate button below.
      </p>
      <Spacer height="3vh" />
      <StyledDiv>
        <Button
          label={'Download for MAC'}
          minWidth="45%"
          clickHandler={clickHandler}
        />
        <Button label={'Windows coming soon'} minWidth="45%" disabled={true} />
      </StyledDiv>
      <SpanStyled>Please note: only Sierra and above are supported</SpanStyled>
      <Spacer height="5vh" />
      <h2>Step 2: Copy study link and open app</h2>
      <p>
        Copy the study link below and click on paste icon once you open the
        Vision Project app.
      </p>
      <Spacer height="3vh" />
      <p>Study Link</p>
      <Spacer height="0.5vh" />
      <CopyDiv>
        <StudyLink type={'text'} value={studyLink} readOnly={true}></StudyLink>
        <CopyButton onClick={onCopyClipboard}>
          <img src={copy} alt={'copy'} />
        </CopyButton>
      </CopyDiv>
    </>
  );
};
