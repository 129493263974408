import { RouterProvider } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import React from 'react';
import { router } from './core/router';
import { GlobalStyles } from './components/globalStyles';
import { addZendeskWidget } from './utils/zendeskWidget';

const ZendeskWidget = (): JSX.Element => {
  // we cannot add void type function in React node
  // So we have to return empty element to satisfy lint error
  addZendeskWidget();
  return <></>;
};

ReactDOM.createRoot(document.getElementById('app')).render(
  <React.StrictMode>
    <GlobalStyles />
    <RouterProvider router={router} />
    <ZendeskWidget />
  </React.StrictMode>
);
