import styled from '@emotion/styled';
import { COLORS } from '../types/styles';

interface ButtonStyle {
  minWidth?: string;
  disabled?: boolean;
  clickHandler?: () => void;
}

interface ButtonProps extends ButtonStyle {
  label: string | React.ReactNode;
}

const ButtonStyled = styled.button<ButtonStyle>((props: ButtonStyle) => ({
  minWidth: props.minWidth ?? 'inherit',
  background: 'transparent',
  backgroundColor:
    props.disabled === true ? `${COLORS.NEUTRAL}` : `${COLORS.PRIMARY}`,
  border: `1px solid ${
    props.disabled === true ? COLORS.NEUTRAL : COLORS.PRIMARY
  }`,
  borderRadius: '2px',
  color: '#FFF',
  fontFamily: 'TTNorms',
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '24px',
  outline: '0',
  padding: '10px 24px',
  top: '10px',
  width: 'auto',
  height: '48px',
  ':hover': {
    backgroundColor:
      props.disabled === true ? COLORS.NEUTRAL : COLORS.SECONDARY_TEXT,
    border: `2px solid ${
      props.disabled === true ? COLORS.NEUTRAL : COLORS.PRIMARY
    }`,
    color: props.disabled === true ? '#FFF' : COLORS.PRIMARY,
    cursor: props.disabled === true ? 'inherit' : 'pointer',
  },
}));

export const Button: React.FC<ButtonProps> = (props: ButtonProps) => {
  const { label, minWidth, disabled, clickHandler } = props;
  return (
    <ButtonStyled
      minWidth={minWidth}
      disabled={disabled}
      onClick={clickHandler}
    >
      {label}
    </ButtonStyled>
  );
};
