import styled from '@emotion/styled';
import icon from '../assets/icon.svg';

const Container = styled.div({
  position: 'absolute',
  padding: '1em',
});


export const VpBadge: React.FC = () => {
  return (
    <Container>
      <img alt="icon" src={icon} />
    </Container>
  );
};
