import React from 'react';
import { css, Global } from '@emotion/react';
import TTNorms from '../assets/fonts/TTNorms-Regular.otf';
import TTNormsMedium from '../assets/fonts/TTNorms-Medium.otf';
import TTNormsBold from '../assets/fonts/TTNorms-Bold.otf';
import { COLORS } from '../types/styles';

export const GlobalStyles: React.FC = () => {
  return (
    <Global
      styles={css`
        @font-face {
          font-family: TTNorms;
          src: url('${TTNorms}') format('opentype');
        }

        @font-face {
          font-family: TTNorms;
          font-weight: 500;
          src: url('${TTNormsMedium}') format('opentype');
        }

        @font-face {
          font-family: TTNorms;
          font-weight: bold;
          src: url('${TTNormsBold}') format('opentype');
        }

        body,
        html {
          margin: 0;
          padding: 0;
        }
        * {
          box-sizing: border-box;
        }

        html {
          height: 100%;
        }

        body {
          min-height: 100%;
          font-family: 'TTNorms';
          font-style: normal;
          font-size: 100%;
        }

        h1 {
          font-weight: 500;
          font-size: 2.3em;
          line-height: 2.5em;
          letter-spacing: -0.008em;
          margin: 0;
        }

        h2 {
          font-weight: 500;
          font-size: 2em;
          line-height: 2em;
          letter-spacing: -0.008em;
          margin: 0;
        }

        h3 {
          font-weight: 500;
          font-size: 1.25em;
          line-height: 1.75px;
          letter-spacing: -0.008em;
          margin: 0;
        }

        h4 {
          font-weight: 500;
          font-size: 1em;
          line-height: 1.5em;
          letter-spacing: -0.004em;
        }

        h5 {
          font-weight: 500;
          font-size: 1em;
          line-height: 1.5em;
          letter-spacing: -0.004em;
          margin: 0;
        }

        h6 {
          font-weight: 500;
          font-size: 0.8em;
          line-height: 1.5em;
          letter-spacing: -0.002em;
        }

        p {
          font-weight: 400;
          font-size: 1em;
          line-height: 1.5em;
          letter-spacing: -0.004em;
          margin: 0;
        }

        a {
          color: ${COLORS.PRIMARY_TEXT};
        }

        ul {
          list-style-type: none;
          padding-inline-start: 0;
        }

        #app {
          min-height: 100%;
        }
      `}
    />
  );
};
