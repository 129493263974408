import styled from '@emotion/styled';
import eyeTracking from '../../assets/eye-tracking.svg';
import cash from '../../assets/cash.svg';
import research from '../../assets/research.svg';

const Container = styled.div({
  padding: '0 6vw',
});

const StyledLi = styled.li({
  display: 'inline-flex',
  marginBottom: '54px',
});

const TextContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  marginLeft: '1.2vw',
  alignItems: 'center',
});

const H2Styled = styled.h2({
  lineHeight: '1.2em',
});

const ImgStyled = styled.img({
  height: '48px',
  width: '48px',
  margin: '1em 0',
});

export const InfoList: React.FC = () => {
  return (
    <Container>
      <H2Styled>
        You are invited to take part in an innovative eye tracking study
      </H2Styled>
      <ul>
        <StyledLi>
          <ImgStyled src={eyeTracking} />
          <TextContainer>
            <p>
              Contribute to a research to understand natural user browsing
              behaviour.
            </p>
          </TextContainer>
        </StyledLi>
        <StyledLi>
          <ImgStyled src={cash} />
          <TextContainer>
            <p>Earn cash while browsing the web. Every session is anonymous.</p>
          </TextContainer>
        </StyledLi>
        <StyledLi>
          <ImgStyled src={research} />
          <TextContainer>
            <p>
              Join over 50,000 people around the world who already participated
              in the study.
            </p>
          </TextContainer>
        </StyledLi>
      </ul>
    </Container>
  );
};
