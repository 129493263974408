import { Outlet } from 'react-router-dom';
import { Row } from '../components/gridElements';
import { VpBadge } from '../components/vpBadge';

export const Layout: React.FC = () => {
  return (
    <>
      <VpBadge />
      <Row id="row">
        <Outlet />
      </Row>
    </>
  );
};
