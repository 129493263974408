import styled from '@emotion/styled';
import { COLORS } from '../types/styles';

const Row = styled.div({
  display: 'flex',
  flexDirection: 'row',
});

interface ColumnProps {
  flex?: string;
  background?: string;
  color?: string;
  padding?: string;
  position?: 'absolute' | 'relative' | 'initial';
}
const Column = styled.div((props: ColumnProps) => ({
  width: props.flex ?? '50%',
  background: props.background ?? 'white',
  color: props.color ?? COLORS.PRIMARY_TEXT,
  position: props.position ?? 'initial',
  padding: props.padding ?? '2vh',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

export { Row, Column };
